// src/Dashboard.js
import React from "react";

function Dashboard() {
  return (
    <div className="bg-white rounded-lg shadow-lg p-6 mt-6 w-full max-w-4xl">
      <h2 className="text-2xl font-bold mb-4">Dashboard</h2>
      <p className="text-gray-700">
        Welcome to the Flores' Girls Dashboard! Here you can manage your
        accounts and view important details.
      </p>
      {/* Add more content and design elements as needed */}
    </div>
  );
}

export default Dashboard;
