import React from "react";

const Resources = () => {
  return (
    <main className="flex-grow w-full px-6 py-12 flex flex-col items-center">
      {/* Description Section */}
      <section className="bg-white rounded-lg shadow-lg p-8 w-full max-w-4xl mb-8 text-center">
        <h1 className="text-3xl font-bold mb-4">RESOURCES</h1>{" "}
        {/* Changed font size to text-3xl to match Dashboard */}
        <p className="text-gray-700 mb-4">
          Empower Our Girls in Their Financial Journey. Welcome to our curated
          collection of financial wisdom! This resource hub is designed to
          support our girls' path to financial literacy and life success.
        </p>{" "}
        {/* Reduced the vertical margin to mb-4 */}
      </section>

      {/* Resources Sections */}
      <section className="grid gap-6 sm:grid-cols-1 md:grid-cols-3 w-full max-w-5xl">
        {" "}
        {/* Reduced gap from gap-8 to gap-6 */}
        {/* Financial Education Section */}
        <div className="bg-white rounded-lg shadow-md p-4">
          {" "}
          {/* Reduced padding from p-6 to p-4 */}
          <h2 className="text-md font-bold mb-2">
            KID FINANCIAL EDUCATION
          </h2>{" "}
          {/* Changed font size to text-md */}
          <ul className="space-y-2">
            <li>
              <a
                href="https://www.khanacademy.org"
                className="text-red-500 hover:underline"
              >
                Khan Academy
              </a>
            </li>
            <li>
              <a
                href="https://itunes.apple.com/us/app/peter-pigs-money-counter/id1093223171?mt=8"
                className="text-red-500 hover:underline"
              >
                Peter Pig's Money Counter
              </a>
            </li>
            <li>
              <a
                href="https://files.consumerfinance.gov/f/documents/cfpb_parents_pretend-play-activity.pdf"
                className="text-red-500 hover:underline"
              >
                Pretend Play - Activity sheet
              </a>
            </li>
          </ul>
        </div>
        {/* Parent Resources Section */}
        <div className="bg-white rounded-lg shadow-md p-4">
          {" "}
          {/* Reduced padding from p-6 to p-4 */}
          <h2 className="text-md font-bold mb-2">PARENT RESOURCES</h2>{" "}
          {/* Changed font size to text-md */}
          <ul className="space-y-2">
            <li>
              <a
                href="https://www.scholastic.com/parents/family-life/financial-literacy/dollars-and-sense-money-management-kids.html"
                className="text-red-500 hover:underline"
              >
                Financial Literacy for Kids
              </a>
            </li>
            <li>
              <a
                href="https://www.parents.com/parenting/money/family-finances/teaching-kids-about-money-an-age-by-age-guide/"
                className="text-red-500 hover:underline"
              >
                Money Management for Families
              </a>
            </li>
            <li>
              <a
                href="https://www.investopedia.com/financial-education-resources-kids-5216826"
                className="text-red-500 hover:underline"
              >
                Teaching Kids About Money
              </a>
            </li>
          </ul>
        </div>
        {/* Learn More Section */}
        <div className="bg-white rounded-lg shadow-md p-4">
          {" "}
          {/* Reduced padding from p-6 to p-4 */}
          <h2 className="text-md font-bold mb-2">LEARN MORE</h2>{" "}
          {/* Changed font size to text-md */}
          <ul className="space-y-2">
            <li>
              <a
                href="https://www.fool.com/investing/how-to-invest/stocks/"
                className="text-red-500 hover:underline"
              >
                Investing for Beginners
              </a>
            </li>
            <li>
              <a
                href="https://smartasset.com"
                className="text-red-500 hover:underline"
              >
                Financial Calculators & Guides
              </a>
            </li>
            <li>
              <a
                href="https://www.morningbrew.com/daily"
                className="text-red-500 hover:underline"
              >
                Morning Brew - Financial Newsletter
              </a>
            </li>
          </ul>
        </div>
      </section>
    </main>
  );
};

export default Resources;
