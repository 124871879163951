import React from "react";

const CharlieBear = () => {
  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center">
      <main className="flex-grow w-full px-6 py-12 flex flex-col items-center">
        <section className="bg-white rounded-lg shadow-lg p-8 w-full max-w-4xl mb-8 text-center">
          <h1 className="text-4xl font-bold mb-4 text-yellow-500">
            CHARLIE BEAR
          </h1>
          <p className="text-lg font-bold mb-4 text-gray-800">
            Empower Our Girls in Their Financial Journey
          </p>
          <p className="text-gray-600">
            Welcome to our curated collection of financial wisdom! This resource
            hub is designed to support our girls' path to financial literacy and
            life success.
          </p>
        </section>

        {/* Investments Section */}
        <section className="bg-white rounded-lg shadow-md p-6 w-full max-w-5xl mb-8">
          <h2 className="text-lg font-bold mb-4">INVESTMENTS</h2>
          <ul className="grid gap-4 grid-cols-2 sm:grid-cols-3">
            <li>
              <a
                href="https://gifts.acorns.com/?code=YE7HAG"
                className="text-red-500 hover:underline"
              >
                Acorns Account - Send a gift!
              </a>
            </li>
          </ul>
        </section>

        {/* Bank Account Section */}
        <section className="bg-white rounded-lg shadow-md p-6 w-full max-w-5xl mb-8">
          <h2 className="text-lg font-bold mb-4">BANK ACCOUNT / DEBIT</h2>
          <ul className="grid gap-4 grid-cols-2 sm:grid-cols-3">
            <li>
              <a
                href="https://gift.gohenry.com/iRDU"
                className="text-red-500 hover:underline"
              >
                Add to Everyday Spend
              </a>
            </li>
            <li>
              <a
                href="https://gift.gohenry.com/Bb32"
                className="text-red-500 hover:underline"
              >
                Add to 9th Birthday Milestone
              </a>
            </li>
            {/* <li>
              <a
                href="#"
                className="text-red-500 hover:underline"
              >
                Allowance
              </a>
            </li> */}
          </ul>
        </section>

        {/* Wishlist Section */}
        <section className="bg-white rounded-lg shadow-md p-6 w-full max-w-5xl">
          <h2 className="text-lg font-bold mb-4">WISHLISTS</h2>
          <ul className="grid gap-4 grid-cols-2 sm:grid-cols-3">
            <li>
              <a
                href="https://www.amazon.com/hz/wishlist/ls/1CLXZ3NWMU838?ref_=wl_share"
                className="text-red-500 hover:underline"
              >
                Amazon
              </a>
            </li>
            {/* <li>
              <a
                href="https://www.piggybot.com"
                className="text-red-500 hover:underline"
              >
                PiggyBot
              </a>
            </li>
            <li>
              <a
                href="https://www.gohenry.com"
                className="text-red-500 hover:underline"
              >
                GoHenry
              </a>
            </li> */}
          </ul>
        </section>
      </main>
    </div>
  );
};

export default CharlieBear;
