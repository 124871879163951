import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
} from "react-router-dom";
import AboutModal from "./AboutModal";
import ContactModal from "./ContactModal";
import WhyIBuiltThisAppModal from "./WhyIBuiltThisAppModal";
import Dashboard from "./Dashboard";
import Resources from "./Resources";
import Penelope from "./Penelope";
import CharlieBear from "./CharlieBear";
import Sofia from "./Sofia";
import penelopeImage from "./images/penelope-image.png";
import charlieBearImage from "./images/charlie-bear-image.png";
import sofiaImage from "./images/sofia-image.png";
import googleCalendarLogo from "./images/google-calendar-logo.gif"; // Add Google Calendar logo image import
import "./index.css"; // Import Tailwind CSS

function App() {
  const [showAbout, setShowAbout] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [showWhyIBuiltThisApp, setShowWhyIBuiltThisApp] = useState(false);

  return (
    <Router>
      <div className="bg-gray-100 min-h-screen flex flex-col items-center">
        {/* Header */}
        <header className="flex items-center justify-between w-full p-6 bg-white shadow-md">
          <div className="text-2xl font-bold text-red-500 flex items-center">
            <span className="bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center mr-2">
              F
            </span>
            Flo
          </div>

          {/* Centered Navigation */}
          <nav className="flex space-x-8 mx-auto">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? "text-red-500 font-bold border-b-2 border-red-500"
                  : "text-gray-600 hover:text-red-500 transition duration-300 ease-in-out"
              }
            >
              Dashboard
            </NavLink>
            <NavLink
              to="/resources"
              className={({ isActive }) =>
                isActive
                  ? "text-red-500 font-bold border-b-2 border-red-500"
                  : "text-gray-600 hover:text-red-500 transition duration-300 ease-in-out"
              }
            >
              Resources
            </NavLink>
          </nav>
        </header>

        {/* Main Content with Routes */}
        <Routes>
          <Route
            path="/"
            element={
              <main className="flex-grow w-full px-6 py-12 flex flex-col items-center">
                {/* Description Section */}
                <section className="bg-white rounded-lg shadow-lg p-8 w-full max-w-4xl mb-8 text-center">
                  <h1 className="text-4xl font-bold mb-4">Flores Girls</h1>
                  <p className="text-gray-700 mb-6">
                    Thank you for supporting our daughters' financial growth and
                    education. This site is a single place where you can find
                    resources to add to their investments goals, access our
                    family calendar, and celebrate milestones together as a
                    family.
                  </p>
                </section>

                {/* Cards Section */}
                <section className="grid gap-8 sm:grid-cols-1 md:grid-cols-3 w-full max-w-5xl">
                  {/* Penelope Card */}
                  <div
                    className="bg-white rounded-lg shadow-lg p-6 flex flex-col justify-between items-center relative bg-cover bg-center bg-no-repeat"
                    style={{ backgroundImage: `url(${penelopeImage})` }}
                  >
                    <div className="bg-white bg-opacity-40 backdrop-blur-md rounded-lg p-4 w-full">
                      <h2 className="text-lg font-bold mb-2 text-center text-black">
                        PENÈLOPE
                      </h2>
                      <p className="text-sm text-center text-black">
                        Add to savings and investment progress, view wishlist
                        for gift ideas, and plan for her bright future.
                      </p>
                    </div>
                    <NavLink
                      to="/penelope"
                      className="absolute bottom-4 right-4 flex items-center justify-center bg-white border border-gray-300 rounded-full p-2 shadow hover:bg-red-500 hover:text-white transition duration-200"
                    >
                      &rarr;
                    </NavLink>
                  </div>

                  {/* Charlie Bear Card */}
                  <div
                    className="bg-white rounded-lg shadow-lg p-6 flex flex-col justify-between items-center relative bg-cover bg-center bg-no-repeat"
                    style={{ backgroundImage: `url(${charlieBearImage})` }}
                  >
                    <div className="bg-white bg-opacity-40 backdrop-blur-md rounded-lg p-4 w-full">
                      <h2 className="text-lg font-bold mb-2 text-center text-black">
                        CHARLIE BEAR
                      </h2>
                      <p className="text-sm text-center text-black">
                        Charlie Bear's financial adventure: Add to her growing
                        investments, add to her allowance savings, and nurture
                        her money skills.
                      </p>
                    </div>
                    <NavLink
                      to="/charlie-bear"
                      className="absolute bottom-4 right-4 flex items-center justify-center bg-white border border-gray-300 rounded-full p-2 shadow hover:bg-red-500 hover:text-white transition duration-200"
                    >
                      &rarr;
                    </NavLink>
                  </div>

                  {/* Sofia Card */}
                  <div
                    className="bg-white rounded-lg shadow-lg p-6 flex flex-col justify-between items-center relative bg-cover bg-center bg-no-repeat"
                    style={{ backgroundImage: `url(${sofiaImage})` }}
                  >
                    <div className="bg-white bg-opacity-40 backdrop-blur-md rounded-lg p-4 w-full">
                      <h2 className="text-lg font-bold mb-2 text-center text-black">
                        SOFIA
                      </h2>
                      <p className="text-sm text-center text-black">
                        Select from her savings account, add to her savings
                        targets, and guide her financial learning.
                      </p>
                    </div>
                    <NavLink
                      to="/sofia"
                      className="absolute bottom-4 right-4 flex items-center justify-center bg-white border border-gray-300 rounded-full p-2 shadow hover:bg-red-500 hover:text-white transition duration-200"
                    >
                      &rarr;
                    </NavLink>
                  </div>
                </section>

                {/* Family Calendar Card */}
                <section className="mt-8 w-full max-w-5xl">
                  <div className="bg-white rounded-lg shadow-lg p-6 flex flex-col justify-between items-center relative">
                    <img
                      src={googleCalendarLogo}
                      alt="Google Calendar"
                      className="w-32 h-32 object-contain mb-4"
                    />
                    <h2 className="text-lg font-bold mb-2 text-center text-black">
                      Family Calendar
                    </h2>
                    <p className="text-sm text-center text-black mb-4">
                      Stay up to date with important family events and
                      milestones.
                    </p>
                    <a
                      href="https://calendar.google.com/calendar/u/0?cid=ZGd0b2xvbmVAZ21haWwuY29t"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-red-500 text-white px-6 py-2 rounded-full hover:bg-red-600 transition duration-300"
                    >
                      View Family Calendar
                    </a>
                  </div>
                </section>
              </main>
            }
          />
          <Route path="/resources" element={<Resources />} />
          <Route path="/penelope" element={<Penelope />} />
          <Route path="/charlie-bear" element={<CharlieBear />} />
          <Route path="/sofia" element={<Sofia />} />
          <Route path="*" element={<div>404 Not Found</div>} />
        </Routes>

        {/* Modals */}
        {showAbout && <AboutModal onClose={() => setShowAbout(false)} />}
        {showContact && <ContactModal onClose={() => setShowContact(false)} />}
        {showWhyIBuiltThisApp && (
          <WhyIBuiltThisAppModal
            onClose={() => setShowWhyIBuiltThisApp(false)}
          />
        )}

        {/* Footer */}
        <footer className="w-full bg-white shadow-md p-8 text-center">
          <div className="text-red-500 font-bold text-xl">Flo</div>
          <div className="flex justify-center space-x-12 mt-4 text-sm text-gray-500">
            <button
              onClick={() => setShowAbout(true)}
              className="hover:underline"
            >
              About
            </button>
            <button
              onClick={() => setShowContact(true)}
              className="hover:underline"
            >
              Contact
            </button>
            <button
              onClick={() => setShowWhyIBuiltThisApp(true)}
              className="hover:underline"
            >
              Why I built this app?
            </button>
          </div>
          <div className="flex justify-center space-x-4 mt-8 text-gray-600">
            <a
              href="#"
              className="hover:text-red-500 transition duration-300 ease-in-out"
            >
              Designed & Built with ♥ By Johann Flores
            </a>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
