import React from "react";

const AboutModal = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-red-500 bg-opacity-90 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg max-w-lg w-full text-center shadow-lg">
        <h2 className="text-3xl font-bold mb-4 text-red-500">About</h2>
        <p className="text-gray-700 mb-4">
          This application is built for the Flores family to support the
          financial education and growth of their daughters.
        </p>
        <button
          onClick={onClose}
          className="bg-red-500 text-white px-4 py-2 rounded-full hover:bg-red-600 transition duration-300"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default AboutModal;
